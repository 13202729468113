<ng-container>
	<div class="modal-header">
		<h5 class="modal-title">
			<span>Thông tin người dùng</span>
		</h5>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-content border-0 ">
		<form class="form" autocomplete="off" (ngSubmit)="onUpdate()">
			<div class="modal-body row">
				<div class="col-md-6">
					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-4 p-0 m-0">
							User name
						</label>
						<input type="text" name="name" class="form-control" [(ngModel)]="formState.name">
					</div>

					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-4 p-0 m-0">
							Phone number
						</label>
						<input type="text" name="phone" [disabled]="true" class="form-control" [(ngModel)]="formState.phone">
					</div>

					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-4 p-0 m-0">
							Email
						</label>
						<input type="text" name="email" class="form-control" [(ngModel)]="formState.email">
					</div>

					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-4 p-0 m-0">
							Password
						</label>
						<input type="password" name="password" class="form-control" [(ngModel)]="formState.password">
					</div>

					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-4 p-0 m-0">
							Type again password
						</label>
						<input type="password" name="re_password" class="form-control" [(ngModel)]="formState.re_password">
					</div>
				</div>
				<div class="col-md-6 d-flex flex-column justify-content-between">


					<!-- <div class="form-group d-flex align-items-center">
                        <label class="font-weight-bold small col-md-3 p-0 m-0">
                            Tỉnh thành
                        </label>
                        <div class="col-md-9 px-0">
                            <ng-select
                                name="provinces"
                                bindLabel="name"
                                class="form-control"
                                placeholder="Chọn tỉnh thành"
                                [items]="configurableProvinces"
                                [(ngModel)]="formState.province"
                                (ngModelChange)="onLoadSubLocation(formState.province?.code, 'district')"
                            >
                                Tỉnh thành
                            </ng-select>
                        </div>
                    </div>

                    <div class="form-group d-flex align-items-center">
                        <label class="font-weight-bold small col-md-3 p-0 m-0">
                            Quận huyện
                        </label>
                        <div class="col-md-9 px-0">
                            <ng-select
                                name="districts"
                                bindLabel="name"
                                class="form-control"
                                placeholder="Chọn quận huyện"
                                [items]="configurableDistricts"
                                [(ngModel)]="formState.district"
                                (ngModelChange)="onLoadSubLocation(formState.district?.code, 'ward')"
                            >
                                Quận huyện
                            </ng-select>
                        </div>
                    </div>

                    <div class="form-group d-flex align-items-center">
                        <label class="font-weight-bold small col-md-3 p-0 m-0">
                            Phường xã
                        </label>
                        <div class="col-md-9 px-0">
                            <ng-select
                                name="wards"
                                bindLabel="name"
                                class="form-control"
                                placeholder="Chọn phường xã"
                                [items]="configurableWards"
                                [(ngModel)]="formState.ward"
                            >
                                Phường xã
                            </ng-select>
                        </div>
                    </div> -->

					<div class="form-group d-flex align-items-center">
						<label class="font-weight-bold small col-md-3 p-0 m-0">
							Note
						</label>
						<input type="text" name="note" class="form-control" placeholder="Enter note" [(ngModel)]="formState.note">
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-success mr-2" (click)="onUpdate()">
					<i class="fa fa-save mr-1"></i>
					Save
				</button>
				<button type="button" class="btn btn-secondary" (click)="closeModal()">
					<i class="fa fa-ban mr-1"></i>
					Skip
				</button>
			</div>
		</form>
	</div>
</ng-container>