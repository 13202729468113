import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';

// Config Module
import { HttpUtilities } from 'src/app/common/helpers';
import { environment } from 'src/environments/environment';
@Injectable()
export class ExcelService {
    // Declaration variable
    API_URI = environment.API_URI;
    headers: HttpHeaders;
    constructor(
        private http: HttpClient,
        private httpService: HttpUtilities
    ) { this.headers = this.httpService.getHttpClientHeaders(); }

    /**
     * Folder upload
     *
     * @public
     */
    UploadGroup = {
        USER: 'users',
        BANNER: 'banners',
        PRODUCT: 'products',
        CATEGORY: 'categories',
    };

    /**
     * Read File
     * @param formData
     * @param groupName
     */
    readFile(formData: any, groupName: string) {
        return this.http.post(
            this.API_URI + '/v1/excels/read-file?group=' + groupName,
            formData,
            {
                headers: {
                    Authorization: this.httpService.loadJwtToken()
                }
            },
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Export File
     * @param fileData
     * @param fileName
     */
    exportFile(fileData: any[], fileName: string) {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(fileData);
        const workbook: XLSX.WorkBook = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // save file
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        fs.saveAs(blob, `${fileName}-${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}.xlsx`);
    }
    /**
     * Order Excel
     * @param requestBody
     */
    orderExcel(requestBody: any) {
        return this.http.post(
            `${this.API_URI}/v1/order-excels/report`,
            requestBody,
            {
                headers: this.headers
            },
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }
}
