export class PostCategory {
    // detail
    id: string;
    name: string;
    note: string;
    slug: string;
    logo: string;
    image: string;

    /**
     * Set data
     * @param {*} state
     */
    setData(state) {
        this.id = state.id;
        this.name = state.name;
        this.note = state.note;
        this.slug = state.slug;
        this.logo = state.logo;
        this.image = state.image;
    }
}
