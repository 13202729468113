export class Banner {
    'id': number;
    'url': string;
    'type': string;
    'title': string;
    'content': string;
    'position': string;
    'image_url': string;
    'mobile_url': string;

    // manager
    'is_visible': boolean;
    'is_active': boolean;
    'created_by': object;
    'created_at': Date;
    'updated_by': object;
    'updated_at': Date;

    /**
     * Set data
     * @param {*} state
     */
    setData(state) {
        this.url = state.url;
        this.type = state.type;
        this.title = state.title;
        this.content = state.content;
        this.position = state.position;
        this.image_url = state.image_url;
        this.mobile_url = state.mobile_url;
        this.is_visible = state.is_visible;
    }
}