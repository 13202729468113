export class Article {
    'id': string;
    'type': string;
    'title': string;
    'slug': string;
    'avatar': string;
    'content': string;
    'categories': any[];
    'hashtag': [string];
    'description': string;

    // social seo
    'breadcrumbs': any[];
    'meta_url': string;
    'meta_title': string;
    'meta_image': string;
    'meta_keyword': string;
    'meta_description': string;

    // manager
    'is_visible': boolean;
    'is_home_visible': boolean;
    'is_active': boolean;
    'created_by': object;
    'created_at': Date;
    'updated_by': object;
    'updated_at': Date;

    /**
     * Set data
     * @param {*} state
     */
    setData(state) {
        this.type = state.type;
        this.title = state.title;
        this.slug = state.slug;
        this.avatar = state.avatar;
        this.content = state.content;
        this.hashtag = state.hashtag;
        this.categories = state.categories;
        this.description = state.description;

        this.meta_image = this.avatar;
        this.meta_url = state.meta_url;
        this.meta_title = state.meta_title;
        this.meta_keyword = state.meta_keyword;
        this.meta_description = state.meta_description;
        this.breadcrumbs = state.breadcrumbs;

        this.is_home_visible = state.is_home_visible;
        this.is_visible = state.is_visible;
    }
}