import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as DecoupledEditor from "./build/ckeditor";
// Plugins
import { CKEditorConfig } from 'src/app/config/dynamic-ckeditor.config';
// import * as DecoupledEditor from 'angular-ckeditor-5/build/ckeditor';
import UploadAdapter from './upload-adapter.component';

@Component({
    selector: 'app-ckeditor',
    templateUrl: './ckeditor.component.html',
    styleUrls: ['./ckeditor.component.scss']
})

export class CkeditorComponent implements OnInit {
    // Declaration IN | OUT
    @Input() model: string;
    @Output() callback = new EventEmitter<string>();

    // Variables and config
    public Editor = DecoupledEditor;
    public config = CKEditorConfig;

    ngOnInit() {
        if (!this.model) {
            this.model = '';
        }
    }

    /**
     * Upload Image Adapter
     * @param editor
     */
    OnUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
        };
    }

    /**
     * On ready CkEditor
     * @param editor
     */
    public onReady(editor) {
        // Initialization CkEditor
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );

        // Handle upload image in CkEditor
        this.OnUploadAdapterPlugin(editor);
    }

    /**
     * Change data CkEditor
     * @param event
     */
    onChange(event) {
        this.callback.emit(this.model);
    }

}
