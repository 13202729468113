import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Config Module
import { environment } from 'src/environments/environment';
import { HttpUtilities } from 'src/app/common/helpers/http-utils.helper';

// Modules
import { Staff } from 'src/app/view/pages/config/_models';

@Injectable()
export class StaffService {
    headers: HttpHeaders;
    API_URI = environment.API_URI + '/v1/staffs';

    constructor(
        private http: HttpClient,
        private httpService: HttpUtilities
    ) {
        this.headers = this.httpService.getHttpClientHeaders();
    }

    /**
     * List
     *
     * @param {String} queryParams
     */
    list(params: any = {}) {
        delete params.page;
        return this.http.get(
            this.API_URI,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Create
     * @param {Staff} requestBody
     */
    create(requestBody: Staff) {
        return this.http.post(
            this.API_URI,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Detail
     * @param {String} staffId
     */
    detail(staffId: any) {
        return this.http.get(
            this.API_URI + `/${staffId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Update
     * @param {String} staffId
     * @param {Staff} requestBody
     */
    update(staffId: string, requestBody: Staff) {
        return this.http.put(
            this.API_URI + `/${staffId}`,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Delete
     * @param {String} staffId
     */
    delete(staffId: string) {
        return this.http.delete(
            this.API_URI + `/${staffId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Block
     * @param {String} staffId
     */
    block(staffId: string) {
        return this.http.post(
            this.API_URI + `/${staffId}/block`,
            null,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Active
     * @param {String} staffId
     */
    active(staffId: string) {
        return this.http.post(
            this.API_URI + `/${staffId}/active`,
            null,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Get role status
     */
    listStatuses() {
        return [
            { id: '', name: 'All', selected: true },
            { id: 'active', name: 'Active', selected: false },
            { id: 'inactive', name: 'Inactive', selected: false }
        ]
    }
}
