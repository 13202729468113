import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { isNumber } from 'lodash';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable({ providedIn: 'root' })
export class CustomDateAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '/';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = value.toString().split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable({ providedIn: 'root' })
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.toString().split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }

    /**
     * Format Date
     * @param value
     * @param format
     * @returns
     */
    formatDate(value: any, format?: string): NgbDateStruct {
        if (value) {
            let parseDate: any = [];
            if (isNumber(value)) {
                const date = moment.unix(value).format(format ? format : 'DD/MM/YYYY');
                parseDate = date.toString().split(this.DELIMITER);
            } else {
                const date = moment(value).format(format ? format : 'DD/MM/YYYY');
                parseDate = date.toString().split(this.DELIMITER);
            }

            return {
                day: parseInt(parseDate[0], 10),
                month: parseInt(parseDate[1], 10),
                year: parseInt(parseDate[2], 10)
            };
        }
        return null;
    }

    /**
     * Convert to date time
     * @param date
     * @param time
     * @returns
     */
    toDateTimeString(date: string, time: any) {
        if (date) {
            let dateString: string;
            let timeString: string;
            if (!date || !time) return null;
            if (date) dateString = moment(date).format('YYYY/MM/DD');
            if (time) timeString = `${time.hour || 0}:${time.minute || 0}:${time.second || 0}`;
            return `${dateString} ${timeString}`;
        }
    }

    /**
     * Parse time
     * @param value
     * @returns
     */
    parseTime(value: any) {
        let hour = 0;
        let second = 0;
        if (isNumber(value)) {
            const date = moment.unix(value).format('DD/MM/YYYY HH:mm');
            const parseDate = date.toString().split(this.DELIMITER);
            if (parseDate.length > 1) {
                const parseTime = parseDate[2].split(' ');
                if (parseTime.length > 1) {
                    const getTime = parseTime[1].split(':');
                    hour = parseInt(getTime[0], 10);
                    second = parseInt(getTime[1], 10);
                }
            }
        }
        return {
            hour: hour,
            minute: second,
            second: 0
        };
    }
}
