export enum AttributeCode {
    SETTING_HOME = 'setting_home',
    SETTING_ABOUT = 'setting_about', // thiết lập -> menu cms
    SETTING_POLICY = 'setting_policy',
    SETTING_GRIEVANCE = 'setting_grievance', // thiết lập -> menu erp
    SETTING_ANNUAL_REPORT = 'setting_annual_report', // thiết lập -> cau hinh erp
    SETTING_CONTACT = 'setting_contact',
    SETTING_PERMISSION = 'setting_permission',
    SETTING_VISON_MISSION_VALUES = 'setting_vision_mission_values',
    SETTING_EMAIL = 'setting_email',
    SETTING_RECRUITMENT = 'setting_recruitment',
}

export class Attribute {
    'code': string;
    'icon': string;
    'type': string;
    'name': string;
    'group': string;
    'require': string;
    'is_visible': boolean;
    'attribute_code': string;
    'attribute_group': string;

    /**
     * Set Data
     * @param state
     */
    setData(state: any = {}) {
        this.code = state.code;
        this.icon = state.icon;
        this.type = state.type;
        this.name = state.name;
        this.group = state.group;
        this.require = state.require;
        this.is_visible = state.is_visible;
        this.attribute_code = state.attribute_code;
        this.attribute_group = this.attribute_group;
    }
}