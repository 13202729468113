import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgModule } from '@angular/core';

// Config
import { IndexedDbConfig } from './config/indexed-db';

// Modules
import { AuthGuard } from './common/guards/auth.guard';
import { ThemeModule } from './view/themes/themes.module';
import { PartialsModule } from './view/partials/partials.module';

// Service
import { HttpUtilities, Utilities } from './common/helpers';
import { ModalService, ToastService, TypeService, UploadService } from './common/services';

// Components
import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // Angular Module
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxIndexedDBModule.forRoot(IndexedDbConfig),

        // Base Module
        PartialsModule,
        ThemeModule,
    ],
    providers: [
        AuthGuard,
        Utilities,
        HttpUtilities,

        // service
        TypeService,
        ModalService,
        ToastService,
        UploadService,
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
