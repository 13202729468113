<ngb-toast
    [autohide]="true"
    [class]="toast.classname"
    [delay]="toast.delay || 5000"
    (hide)="toastService.remove(toast)"
    *ngFor="let toast of toastService.toasts"
>
    <ng-template
        [ngIfElse]="text"
        [ngIf]="isTemplate(toast)"
    >
        <ng-template [ngTemplateOutlet]="toast.message"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.message }}</ng-template>
</ngb-toast>