import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

// Modules
import { Utilities } from 'src/app/common/helpers';
import { LayoutService } from 'src/app/common/services';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
})

export class LayoutComponent implements OnInit, AfterViewInit {
    constructor(
        private router: Router,
        private utilities: Utilities,
        private layout: LayoutService,
    ) { }

    ngOnInit(): void {
        // config route navigation
        this.router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                this.utilities.setLoading(true);
            }
            if (route instanceof NavigationEnd) {
                console.log('end navigation');
            }
        });
    }

    ngAfterViewInit() {
        this.layout.initTheme();
    }
}
