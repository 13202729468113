import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { isEmpty, pick } from 'lodash';
import { TreeviewItem } from 'ngx-treeview';

// Modules
import { AttributeService } from 'src/app/view/pages/config/_services';

@Component({
    selector: 'app-category-search',
    templateUrl: './category-search.component.html',
    providers: [AttributeService]
})

export class CategorySearchComponent implements OnInit, OnChanges {
    // Declaration Input
    @Input() params: any = {};
    @Input() categories: any = [];
    @Output() callback = new EventEmitter<string>();

    // Declaration Config
    configurableCategories: TreeviewItem[];

    constructor(
        private categoryService: AttributeService
    ) { }

    ngOnInit(): void {
        this.onGetCategories();
    }

    ngOnChanges() {
        if (this.categories) {
            const newItem = this.categories[0];
            newItem.value = newItem.code;
            newItem.text = `${newItem.name}`;
            newItem.checked = newItem.false;
            newItem.children=[]
                const dataTransform = new TreeviewItem(newItem);
                this.configurableCategories.push(dataTransform);
            
        }
    }

    /**
     * Get category
     */
    onGetCategories() {
        this.categoryService.list({
            skip: 0,
            limit: 500,
            ...this.params
        }).then(response => {
            const dataTransform = isEmpty(response.code)
                ? response.data.map(c => {
                    c.text = `${c.name}`;
                    c.value = c.code;
                    c.checked = false;
                    c.collapsed = true;
                    c.children=[]
                    // if (c.children && c.children.length) {
                    //     c.children = c.children.map(c2 => {
                    //         c2.text = c2.name;
                    //         c2.value = c2.id;
                    //         c2.checked = false;
                    //         c2.collapsed = true;

                    //         if (c2.children && c2.children.length) {
                    //             c.children = c2.children.map(c3 => {
                    //                 c3.text = c3.name;
                    //                 c3.value = c3.id;
                    //                 c3.checked = false;
                    //                 c3.collapsed = true;
                    //                 return c3;
                    //             });
                    //         }
                    //         return c2;
                    //     });
                    // }
                    // c.children = [
                    //     ...c.children,
                    //     {
                    //         text: 'Tất cả',
                    //         value: c.value,
                    //         checked: false,
                    //         collapsed: true
                    //     }
                    // ];
                    return new TreeviewItem(
                        pick(c, ['text', 'value', 'checked', 'collapsed', 'children'])
                    );
                })
                : [];
            this.configurableCategories = dataTransform;
        }).catch(error => {
            throw Error(error);
        });
    }

    /**
     * Select
     * @param {*} ids`
     */
    onSelect(ids: []) {
        this.callback.emit(ids.join(','))
    }
}
