export class AttributeValue {
    'id': string;
    'slug': string;
    'icon': string;
    'name': string;
    'value': string;
    'content': string;
    'position': string;
    'is_visible': string;
    'meta_url': string;
    'meta_title': string;
    'meta_image': string;
    'meta_keyword': string;
    'meta_description': string;
    'attribute_code': string;
    'attribute_group': string;

    /**
     * Set Data
     * @param state
     */
    setData(state: any = {}) {
        this.slug = state.slug;
        this.name = state.name;
        this.icon = state.icon;
        this.value = state.value;
        this.content = state.content;
        this.position = state.position;
        this.is_visible = state.is_visible;
        this.attribute_code = state.attribute_code;
        this.attribute_group = state.attribute_group;
    }
}