export class Collection {
    'id': string;
    'title': string;
    'slug': string;
    'avatar': string;
    'avatar1': string;
    'cover': string;
    'background': string;
    'content': string;
    'images': [];
    'products': [];
    'description': string;
    'mobile_images': [];
    'mobile_content': string;
    'position': number;

    // social seo
    'breadcrumbs': any[];
    'meta_url': string;
    'meta_title': string;
    'meta_image': string;
    'meta_keyword': string;
    'meta_description': string;

    // manager
    'is_visible': boolean;
    'is_home_visible': boolean;
    'is_active': boolean;
    'created_by': object;
    'created_at': Date;
    'updated_by': object;
    'updated_at': Date;

    /**
     * Set data
     * @param {*} state
     */
    setData(state) {
        this.slug = state.slug;
        this.title = state.title;
        this.cover = state.cover;
        this.avatar = state.avatar;
        this.avatar1 = state.avatar1;
        this.content = state.content;
        this.products = state.products;
        this.background = state.background;
        this.description = state.description;
        this.mobile_content = state.mobile_content;
        this.position = state.position;

        this.meta_image = this.avatar;
        this.meta_url = state.meta_url;
        this.meta_title = state.meta_title;
        this.meta_keyword = state.meta_keyword;
        this.meta_description = state.meta_description;
        this.breadcrumbs = state.breadcrumbs;

        this.is_visible = state.is_visible;
        this.is_home_visible = state.is_home_visible;
        this.images = state.images?.length
            ? state.images.filter(img => img !== null)
            : null;
        this.mobile_images = state.mobile_images?.length
            ? state.mobile_images.filter(img => img !== null)
            : null;
    }
}