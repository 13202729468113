import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { isArray, isEmpty } from 'lodash';

// Modules
import { ExcelService, LayoutService, ModalService } from 'src/app/common/services';

/**
 * File code
 * @enum
 */
enum FileCode {
    // Common files
    VOUCHER_HISTORY = 'VOUCHER_HISTORY',
    PART = 'PART',
    ORDER = 'ORDER',
    ORDER_DETAIL = 'ORDER_DETAIL'
}
@Component({
    selector: 'app-popup-download',
    templateUrl: './popup-download.component.html',
    providers: [
        LayoutService,
        ExcelService,
    ]
})

export class PopupDownloadComponent implements OnInit {
    // Declaration state
    fileUrl: string;
    fileError: string;
    fileCode: FileCode;
    fileName: any = '';
    fileParam: any = {};

    // Declaration config
    service: any = null;
    loading: any = true;

    constructor(
        private excelService: ExcelService,
        private layoutService: LayoutService,
        private modalService: ModalService
    ) { }

    async ngOnInit() {
        try {
            await this.onGetService();
            await this.onGetDataExport();
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Get Service
     * @returns
     */
    async onGetService() {
        let store: any = [];
        let exportType: string;
        const params = {
            ...this.onParseParams(
                this.fileParam
            ),
            limit: 100000,
            skip: 0
        };
        switch (this.fileCode) {
            // Common files
            case FileCode.ORDER:
                this.service = await this.excelService.orderExcel({
                    type: 'export',
                    query: params
                });
                break;
            case FileCode.ORDER_DETAIL:
                this.service = await this.excelService.orderExcel({
                    type: 'export',
                    nested: true,
                    query: {
                        ...params,
                        includes: 'payments'
                    }
                });
                break;
            default:
                break;
        }
    }

    /**
     * Get Data Export
     * @private
     */
    async onGetDataExport() {
        try {
            const response = {
                ...this.service
            };
            if (!isEmpty(response)) {
                if (response.code) {
                    this.fileError = response.message;
                    this.loading = false;
                    return;
                }

                // Handle success
                this.fileUrl = response.url;
                const fileName = response.url.split('/') || [];
                this.fileName = fileName.length && fileName.slice(-1)[0];
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Parse params
     * @param params
     * @returns
     */
    onParseParams(params) {
        for (const [key] of Object.entries(params)) {
            if (isEmpty(params[key])) delete params[key];
            if (isArray(params[key]) && params[key]?.length) {
                params[key] = params[key].join(',');
            }
        }
        return params;
    };

    /**
     * Close
     */
    onClose() {
        this.modalService.close(
            PopupDownloadComponent
        );
        $('.popup-download').removeClass('show');
        $('.popup-download').addClass('hidden');
    }
}
