export enum ContactTypes {
    REGISTER = 'register',
    CONTACT = 'contact'
}


export class Contact {
    'id': number;
    'title': string;
    'phone_number': string;
    'send_email': boolean;
    'email': string;
    'content': string;
    'type': string;

    constructor(state: any = {}) {
        if (state) {
            // detail
            if (state.id) this.id = state.id;
            if (state.title) this.title = state.title;
            if (state.phone_number) this.phone_number = state.phone_number;
            if (state.send_email) this.send_email = state.send_email;
            if (state.email) this.email = state.email;
            if (state.content) this.content = state.content;
            if (state.type) this.type = state.type;
        }
    }
}
