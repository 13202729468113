import { Component, OnInit } from '@angular/core';
import { includes } from 'lodash';
import { Utilities } from 'src/app/common/helpers';

// Modules
import { LayoutService, ModalService } from 'src/app/common/services';
import { DynamicMenuConfig } from 'src/app/config/dynamic-menu.config';
import { environment } from 'src/environments/environment';
import { ModalProfileInfoComponent } from '../../../pages/auth/profile/modal-profile-info/modal-profile-info.component';

@Component({
    selector: 'c-header-bottom',
    templateUrl: './header-bottom.component.html',
    styleUrls: ['./header-bottom.component.scss'],
    providers: [
        LayoutService
    ]
})

export class HeaderBottomComponent implements OnInit {
    // Declaration State
    menuConfig: any = [];
    logoConfig = environment.LAYOUT_CONFIG.LOGO_ICON;
    configurableCurrentStore: any = {};
    configurablePermissions: any = [];
    configurableAccount: any = {};
    configurableStores: any = [];

    constructor(
        private utilities: Utilities,
        private modalService: ModalService,
        private layoutService: LayoutService
    ) { }

    ngOnInit(): void {
        this.configurableAccount = this.layoutService.getUser();
        this.configurableStores = this.layoutService.listStore();
        this.configurablePermissions = this.layoutService.listPermission();
        this.configurableCurrentStore = this.layoutService.getCurrentStore();
        this.menuConfig = this.onLoadMenu();
    }

    /**
     * Load Menu
     * @param {*} permissions
     */
    onLoadMenu() {
        const menus = DynamicMenuConfig.items;
        return menus;
    }
		onOpenProfileModal(){
			let modalRef: any;
			modalRef = this.modalService.open(ModalProfileInfoComponent, { size: 'lg' });
			modalRef.componentInstance.staffId = this.configurableAccount?.id;
			return modalRef;
		}
    /**
     * Change Store
     * @param {*} store
     */
    onChangeCurrentStore(store: any = {}) {
        try {
            // TODO:: Check Store Is Active
            this.layoutService.changeCurrentStore(store)
            window.location.reload();
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Sign out
     * @param {*} clear
     */
    onSignOut() {
        try {
            this.utilities.setLoading(true);
            this.layoutService.resetConfig();
            window.location.href = '/auth/login';
            return true;
        } catch (error) {
            throw Error(error);
        }
    }
}
