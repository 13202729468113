import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
})

export class DateRangeComponent implements OnInit {
    // Declaration State
    toDate = null;
    fromDate = null;
    hoveredDate = null;

    // Declaration Output
    @Output() dateSelect = new EventEmitter<any>();

    ngOnInit(): void { }

    /**
     * Select Time
     * @param date
     */
    onSelect(date) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !date.after(this.fromDate)) {
            this.toDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = date.after(this.fromDate) ? date : this.toDate;
            this.fromDate = !date.after(this.fromDate) ? date : this.fromDate;
        }

        // prepare & submit params
        if (this.fromDate && this.toDate) {
            const minTime = `${this.fromDate?.year}/${this.fromDate?.month}/${this.fromDate?.day}`;
            const maxTime = `${this.toDate?.year}/${this.toDate?.month}/${this.toDate?.day}`;
            const timeValue = `${moment(minTime).format('DD/MM/YYYY')} - ${moment(maxTime).format('DD/MM/YYYY')}`;
            this.dateSelect.emit({ min_time: minTime, max_time: maxTime, time_value: timeValue });
        }
    }

    /**
     * Is hovered date range
     * @param date
     * @returns
     */
    isHoveredDate(date) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    /**
     * Is inside date range
     * @param date
     * @returns
     */
    isInsideDate(date) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    /**
     * Is range date range
     * @param date
     * @returns
     */
    isRangeDate(date) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInsideDate(date) || this.isHoveredDate(date);
    }
}
