export enum StaffStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum StaffNormalStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}
export class Staff {
    'id': number;
    'type': string;
    'role': object;
    'group': object;
    // 'stores': [];
    'status': string;
    'name': string;
    'note': string;
    'phone': string;
    'email': string;
    'avatar': string;
    'cover': string;
    'gender': string;
    'dob': number;
    'birthday': string;
    'tax_code': string;
    'company': string;
    'country': string;
    'language': string;
    'timezone': number;
    'address': string;
    'service': string;
    'password': string;
    'province': {};
    'district': {};
    'ward': {};
    'permissions': [];

    // third party account
    'apple': {};
    'facebook': {};
    'google': {};

    // metric
    'total_debt': number;
    'total_order_price': number;
    'total_invoice_price': number;
    'total_return_price': number;
    'total_point': number;
    'total_purchase': number;
    'last_purchase': Date;

    // config
    'is_active': boolean;
    'device_id': string;
    'ip_address': string;
    'is_verified_phone': boolean;
    'is_verified_email': boolean;
    'is_verified_password': boolean;
    'created_by': object;
    'created_at': string;
    'updated_at': string;

    constructor(state: any = {}) {
        if (state) {
            // detail
            if (state.id) this.id = state.id;
            if (state.type) this.type = state.type;
            if (state.role) this.role = state.role;
            if (state.name) this.name = state.name;
            if (state.note) this.note = state.note;
            if (state.phone) this.phone = state.phone;
            if (state.email) this.email = state.email;
            if (state.avatar) this.avatar = state.avatar;
            if (state.cover) this.cover = state.cover;
            if (state.group) this.group = state.group;
            // if (state.stores) this.stores = state.stores;
            if (state.status) this.status = state.status;
            if (state.gender) this.gender = state.gender;
            if (state.birthday) this.birthday = state.birthday;
            if (state.dob) this.dob = state.dob;
            if (state.tax_code) this.tax_code = state.tax_code;
            if (state.company) this.company = state.company;
            if (state.country) this.country = state.country;
            if (state.language) this.language = state.language;
            if (state.timezone) this.timezone = state.timezone;
            if (state.service) this.service = state.service;
            if (state.address) this.address = state.address;
            if (state.password) this.password = state.password;
            if (state.province) this.province = state.province;
            if (state.district) this.district = state.district;
            if (state.ward) this.ward = state.ward;
            if (state.permissions) this.permissions = state.permissions;
        }
    }
}