<nav class="c-header__bottom">
	<div class="c-container">
		<div class="d-flex align-items-center">
			<a routerLink="/dashboard">
				<img alt="ERP" class="h-logo" [src]="logoConfig">
			</a>
			<ul class="menu">
				<li class="menu__list" *ngFor="let menu of menuConfig">
					<a [routerLink]="menu.page" [routerLinkActive]="['active']" class="menu__link">
						<img [src]="menu.icon">
						{{menu.title}}
					</a>
					<div class="drop-menu" *ngIf="menu.submenu.length">
						<ul class="drop-list">
							<li class="drop-item" *ngFor="let sub of menu.submenu">
								<a [routerLink]="sub.page">
									<span class="dot"></span>
									{{sub.title}}
								</a>
							</li>
						</ul>
					</div>
				</li>
			</ul>
			<div class="header-external location">
				<span>{{ configurableAccount.name }}</span>
				<img src="./assets/media/icons/icon-user-circle.svg" alt="">
				<ul class="external-list">
					<li>
						<a (click)="onOpenProfileModal()">
							<i class="fa fa-user mr-1"></i>
							Profile
						</a>
					</li>
					<li>
						<a href="https://erp.b2c.vn/assets/media/files/document.docx">
							<i class="fa fa-download mr-1"></i>
							Manual document
						</a>
					</li>
					<li>
						<a href="#" (click)="onSignOut()">
							<i class="fa fa-sign-out-alt"></i>
							Logout
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>