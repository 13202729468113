export const DefaultLayoutConfig = {
    listStore: [],
    currentStore: {},
    currentUser: {},
    accessToken: null,
    tokenExpired: null,
    listPermissions: [],
    listTableCollum: {
        PRODUCT: [
            { id: 'thumbnail_url', name: 'Hình ảnh', width: 80, active: true, type: 'image', index: null },
            { id: 'sku', name: 'Mã hàng', width: 150, active: true, type: 'link', index: null },
            { id: 'name', name: 'Tên hàng', width: 420, active: true, type: 'text', index: null },
            { id: 'barcode', name: 'Mã vạch', width: 120, active: false, type: 'text', index: null },
            { id: 'total_quantity', name: 'Tồn kho', width: 90, active: true, type: 'double_number', index: null },
            { id: 'categories', name: 'Nhóm hàng', width: 120, active: false, type: 'array', index: 0 },
            { id: 'stock_min', name: 'Tồn ít nhất', width: 120, active: false, type: 'number', index: null },
            { id: 'type_name', name: 'Loại hàng', width: 100, active: false, type: 'text', index: null },
            { id: 'stock_max', name: 'Tồn nhiều nhất', width: 100, active: false, type: 'number', index: null },
            { id: 'original_price', name: 'Giá vốn', width: 90, active: true, type: 'number', index: null },
            { id: 'price', name: 'Giá bán', width: 90, active: true, type: 'number', index: null },
            { id: 'status_name', name: 'Trạng thái', width: 150, active: false, type: 'text', index: null },
            { id: 'created_at', name: 'Ngày tạo', width: 150, active: false, type: 'text', index: null },
            { id: 'created_by', name: 'Người tạo', width: 100, active: false, type: 'object', index: 'name' },
            { id: 'action', name: 'Thao tác', width: 200, active: true, type: 'text', index: null }
        ],
        INVOICE: [
            { id: 'id', name: 'Số phiếu', width: 50, active: false, type: 'link', index: null },
            { id: 'code', name: 'Mã phiếu', width: 90, active: true, type: 'text', index: null },
            { id: 'created_at', name: 'Thời gian', width: 140, active: true, type: 'text', index: null },
            { id: 'customer', name: 'Khách hàng', width: 160, active: true, type: 'object', index: 'name' },
            { id: 'note', name: 'Note khách hàng', width: 150, active: true, type: 'text', index: null },
            { id: 'hashtag', name: 'Note nội bộ', width: 280, active: true, type: 'text', index: null },
            { id: 'total_price', name: 'Tổng tiền', width: 120, active: true, type: 'number', index: null },
            { id: 'deliveries', name: 'Vận chuyển', width: 120, active: false, type: 'array', index: 'service_name' },
            { id: 'payment', name: 'Thanh toán', width: 120, active: false, type: 'object', index: 'name' },
            { id: 'status_name', name: 'Trạng thái', width: 150, active: false, type: 'text', index: null },
            { id: 'deliveries', name: 'Địa chỉ', width: 150, active: false, type: 'array', index: 'address_name' },
            { id: 'deliveries', name: 'Tỉnh thành', width: 150, active: false, type: 'array', index: 'province_name' },
            { id: 'deliveries', name: 'Quận huyện', width: 150, active: false, type: 'array', index: 'district_name' },
            { id: 'deliveries', name: 'Phường xã', width: 150, active: false, type: 'array', index: 'war_name' },
            { id: 'source', name: 'Nguồn', width: 80, active: true, type: 'text', index: null }
        ],
        ARTICLE: [
            { id: 'title', name: 'Title', width: 400, active: true, type: 'text', index: null },
            { id: 'category_name', name: 'Category name', width: 150, active: true, type: 'text', index: null },
            { id: 'updated_at', name: 'Last updated', width: 120, active: false, type: 'text', index: null }
        ],
        RATING: [
            { id: 'content', name: 'Đánh giá', width: 500, active: true, type: 'text', index: null },
            { id: 'created_at', name: 'Ngày đăng', width: 150, active: true, type: 'text', index: null },
            { id: 'product', name: 'Mã sản phẩm', width: 150, active: true, type: 'object', index: 'sku' },
            { id: 'product', name: 'Tên sản phẩm', width: 200, active: true, type: 'object', index: 'name' },
            { id: 'action', name: 'Thao tác', width: 125, active: true, type: 'text', index: null }
        ],
        COMMENT: [
            { id: 'content', name: 'Hỏi đáp', width: 550, active: true, type: 'text', index: null },
            { id: 'product', name: 'Tên sản phẩm', width: 300, active: true, type: 'object', index: 'name' },
            { id: 'reply_count', name: 'Lượt reply', width: 80, active: true, type: 'number', index: null },
            { id: 'created_at', name: 'Ngày tạo', width: 140, active: true, type: 'text', index: null },
            { id: 'action', name: 'Thao tác', width: 125, active: true, type: 'text', index: null }
        ],
        COLLECTION: [
            { id: 'avatar', name: 'Image', width: 80, active: true, type: 'image', index: null },
            { id: 'title', name: 'Collection name', width: 250, active: true, type: 'text', index: null },
            { id: 'description', name: 'Description', width: 300, active: true, type: 'text', index: null },
        ],
        BRAND: [
            { id: 'icon', name: 'Image', width: 100, active: true, type: 'image', index: null },
            { id: 'name', name: 'Name', width: 350, active: true, type: 'text', index: null },
            { id: 'position', name: 'Position', width: 60, active: true, type: 'text', index: null },
            { id: 'created_at', name: 'Created at', width: 120, active: true, type: 'text', index: null },
            { id: 'updated_at', name: 'Last updated', width: 120, active: true, type: 'text', index: null }
        ],
        ROLE: [
            { id: 'name', name: 'Name role', width: 200, active: true, type: 'link', index: null },
            { id: 'created_by', name: 'Created by', width: 150, active: true, type: 'object', index: 'name' },
            { id: 'created_at', name: 'Created at', width: 150, active: true, type: 'text', index: null },
            { id: 'updated_at', name: 'Last updated', width: 150, active: true, type: 'text', index: null },
            { id: 'status', name: 'Status', width: 150, active: true, type: 'text', index: null }
        ],
        STAFF: [
            { id: 'email', name: 'Email', width: 180, active: true, type: 'link', index: null },
            { id: 'name', name: 'Name', width: 180, active: true, type: 'link', index: null },
            { id: 'created_by', name: 'Created by', width: 150, active: true, type: 'object', index: 'name' },
            { id: 'created_at', name: 'Created at', width: 150, active: true, type: 'text', index: null },
            { id: 'updated_at', name: 'Last updated', width: 150, active: true, type: 'text', index: null },
            { id: 'status', name: 'Status', width: 150, active: true, type: 'text', index: null }
        ],
        CONTACT: [
            { id: 'title', name: 'Title', width: 200, active: true, type: 'link', index: null },
            { id: 'full_name', name: 'Name', width: 150, active: true, type: 'link', index: null },
            { id: 'phone_number', name: 'Phone number', width: 150, active: true, type: 'link', index: null },
            { id: 'email', name: 'Email', width: 150, active: true, type: 'link', index: null },
            // { id: 'created_by', name: 'Người tạo', width: 150, active: true, type: 'object', index: 'name' },
            { id: 'created_at', name: 'Created at', width: 150, active: true, type: 'text', index: null },
            { id: 'updated_at', name: 'Last updated', width: 150, active: true, type: 'text', index: null },
            // { id: 'send_email', name: 'Đã gửi email', width: 150, active: true, type: 'text', index: null }
        ],
    }
}