export const DynamicMenuConfig = {
    items: [
        // {
        //     title: 'Hàng hoá',
        //     page: '/product',
        //     icon: './assets/media/icons/icon-products.svg',
        //     permissions: [
        //         'staff'
        //     ],
        //     submenu: [
        //         {
        //             title: 'Hàng hóa',
        //             icon: 'fa fa-dot',
        //             page: '/product',
        //             permission: 'staff',
        //         },
        //         {
        //             title: 'Danh mục',
        //             icon: 'fa fa-dot',
        //             page: '/product/category',
        //             permission: 'staff',
        //         },
        //         {
        //             title: 'Đánh giá',
        //             icon: 'fa fa-dot',
        //             page: '/product/rating',
        //             permission: 'staff',
        //         },
        //         {
        //             title: 'Hỏi đáp',
        //             icon: 'fa fa-dot',
        //             page: '/product/comment',
        //             permission: 'staff',
        //         }
        //     ]
        // },
        // {
        //     title: 'Giao dịch',
        //     page: '/transaction/order',
        //     icon: './assets/media/icons/icon-checklist.svg',
        //     permissions: [
        //         'staff'
        //     ],
        //     submenu: [
        //         {
        //             title: 'Hoá đơn',
        //             icon: 'fa fa-dot',
        //             page: '/transaction/order',
        //             permission: 'staff',
        //         }
        //     ]
        // },
        {
            title: 'Content',
            page: '/config/setting',
            icon: './assets/media/icons/icon-settings.svg',
            permissions: [
                'staff'
            ],
            submenu: [
                {
                    title: 'Module 1 - Header',
                    icon: 'fa fa-dot',
                    page: '/config/setting',
                    permission: 'staff',
                },
                {
                    title: 'Module 2 - Slider and Banner',
                    icon: 'fa fa-dot',
                    page: '/config/banner',
                    permission: 'staff',
                },
                {
                    title: 'Module 3 - Article',
                    icon: 'fa fa-dot',
                    page: '/config/article',
                    permission: 'staff',
                },
                {
                    title: 'Module 4 - Introduction',
                    icon: 'fa fa-dot',
                    page: '/config/collection',
                    permission: 'staff',
                },


                {
                    title: 'Module 5 - Contact History',
                    icon: 'fa fa-dot',
                    page: '/config/contact',
                    permission: 'general_service_contact_view',
                },
                {
                    title: 'Module 6 - Role',
                    icon: 'fa fa-dot',
                    page: '/config/role',
                    permission: 'general_service_role_view',
                },
                {
                    title: 'Module 7 - Staff',
                    icon: 'fa fa-dot',
                    page: '/config/staff',
                    permission: 'general_service_staff_view',
                },

            ]
        }
    ]
};
