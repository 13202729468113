import { Component, Input, OnChanges } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from 'src/app/common/helpers';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})

export class TimePickerComponent implements OnChanges {
    // Declaration State
    public condition: any = {};
    @Input() state: any = {};
    @Input() field: any = '';

    constructor() { }

    /**
     * Lifecycle
     * @hooks
     */
    ngOnChanges() {
        this.initForm();
    }

    /**
     * Init Form
     * @func
     */
    initForm() {
        const now = new Date();
        const operation = {
            time: this.state[this.field]
                ? {
                    hour: parseInt(this.state[this.field].hour, 0),
                    minute: parseInt(this.state[this.field].minute, 0),
                    second: 0
                }
                : {
                    hour: now.getHours() + 1,
                    minute: now.getMinutes(),
                    second: 0
                }
        }
        this.condition = { ...operation };
        this.state[this.field] = this.condition.time;
    }

    /**
     * Change
     */
    onChange() {
        this.state[this.field] = this.condition.time;
    }
}