export enum RoleStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum RoleNormalStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}
export class Role {
    'id': number;
    'name': string;
    'permissions': [];
    'is_active': boolean;
    'created_at': string;
    'updated_at': string;
    'created_by': object;

    constructor(state: any = {}) {
        if (state) {
            // detail
            if (state.id) this.id = state.id;
            if (state.name) this.name = state.name;
            if (state.permissions) this.permissions = state.permissions;
        }
    }

    /*
    * Get Permissions
    * @public
    */
    public get permissionConfig() {
        return [
            {
                id: 'HE_THONG',
                name: 'System',
                checked: false,
                menus: [
                    {
                        id: 'role_permission',
                        name: 'Role management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'general_service_role_view',
                                name: 'View list',
                                checked: false,
                            },
                            {
                                id: 'general_service_role_create',
                                name: 'Add',
                                checked: false,
                            },
                            {
                                id: 'general_service_role_update',
                                name: 'Update',
                                checked: false,
                            },
                            {
                                id: 'general_service_role_delete',
                                name: 'Delete',
                                checked: false
                            }
                        ]
                    },
                    {
                        id: 'staff_permission',
                        name: 'Staff management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'general_service_staff_view',
                                name: 'View',
                                checked: false,
                            },
                            {
                                id: 'general_service_staff_create',
                                name: 'Add',
                                checked: false,
                            },
                            {
                                id: 'general_service_staff_update',
                                name: 'Update',
                                checked: false,
                            },
                            {
                                id: 'general_service_staff_delete',
                                name: 'Delete',
                                checked: false
                            }
                        ]
                    },
                    // {
                    //     id: 'store_permission',
                    //     name: 'Quản lý chi nhánh',
                    //     checked: false,
                    //     active: true,
                    //     permissions: [
                    //         {
                    //             id: 'general_service_store_view',
                    //             name: 'Xem danh sách',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_store_create',
                    //             name: 'Thêm mới',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_store_update',
                    //             name: 'Cập nhật',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_store_delete',
                    //             name: 'Xoá',
                    //             checked: false
                    //         }
                    //     ]
                    // },
                    // {
                    //     id: 'channel_permission',
                    //     name: 'Quản lý kênh bán',
                    //     checked: false,
                    //     active: true,
                    //     permissions: [
                    //         {
                    //             id: 'general_service_channel_view',
                    //             name: 'Xem danh sách',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_channel_create',
                    //             name: 'Thêm mới',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_channel_update',
                    //             name: 'Cập nhật',
                    //             checked: false,
                    //         },
                    //         {
                    //             id: 'general_service_channel_delete',
                    //             name: 'Xoá',
                    //             checked: false
                    //         }
                    //     ]
                    // }
                ],
            },
            // {
            //     id: 'HANG_HOA',
            //     name: 'Hàng hóa',
            //     checked: false,
            //     menus: [
            //         {
            //             id: 'product_permission',
            //             name: 'Danh mục sản phẩm',
            //             checked: false,
            //             active: true,
            //             permissions: [
            //                 {
            //                     id: 'product_service_product_view',
            //                     name: 'Xem danh sách',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_create',
            //                     name: 'Thêm mới',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_update',
            //                     name: 'Cập nhật',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_delete',
            //                     name: 'Xoá',
            //                     checked: false
            //                 }
            //             ],
            //         },
            //         {
            //             id: 'product_permission',
            //             name: 'Nguyên phụ liệu',
            //             checked: false,
            //             active: true,
            //             permissions: [
            //                 {
            //                     id: 'product_service_product_view',
            //                     name: 'Xem danh sách',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_create',
            //                     name: 'Thêm mới',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_update',
            //                     name: 'Cập nhật',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_product_delete',
            //                     name: 'Xoá',
            //                     checked: false
            //                 }
            //             ],
            //         },
            //         {
            //             id: 'price_book_permission',
            //             name: 'Thiết lập giá',
            //             checked: false,
            //             active: true,
            //             permissions: [
            //                 {
            //                     id: 'product_service_price_book_view',
            //                     name: 'Xem danh sách',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_price_book_create',
            //                     name: 'Thêm mới',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_price_book_update',
            //                     name: 'Cập nhật',
            //                     checked: false,
            //                 },
            //                 {
            //                     id: 'product_service_price_book_delete',
            //                     name: 'Xoá',
            //                     checked: false
            //                 }
            //             ]
            //         },
            //     ]
            // },
            {
                id: 'BÀI VIẾT',
                name: 'Article',
                checked: false,
                menus: [
                    {
                        id: 'article_permission',
                        name: 'Article management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'general_service_article_view',
                                name: 'View',
                                checked: false,
                            },
                            {
                                id: 'general_service_game_create',
                                name: 'Add',
                                checked: false,
                            },
                            {
                                id: 'general_service_article_update',
                                name: 'Update',
                                checked: false,
                            },
                            {
                                id: 'general_service_article_delete',
                                name: 'Delete',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                id: 'BANNER',
                name: 'BANNER',
                checked: false,
                menus: [
                    {
                        id: 'banner_permission',
                        name: 'Banner management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'general_service_banner_view',
                                name: 'View',
                                checked: false,
                            },
                            {
                                id: 'general_service_banner_create',
                                name: 'Add',
                                checked: false,
                            },
                            {
                                id: 'general_service_banner_update',
                                name: 'Update',
                                checked: false,
                            },
                            {
                                id: 'general_service_banner_delete',
                                name: 'Delete',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                id: 'IMAGE',
                name: 'Image',
                checked: false,
                menus: [
                    {
                        id: 'image_permission',
                        name: 'Image management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'file_service_image_upload',
                                name: 'upload',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                id: 'CONTACT',
                name: 'CONTACT',
                checked: false,
                menus: [
                    {
                        id: 'contact_permission',
                        name: 'Contact management',
                        checked: false,
                        active: true,
                        permissions: [
                            {
                                id: 'general_service_contact_view',
                                name: 'View',
                                checked: false,
                            },
                            {
                                id: 'general_service_contact_create',
                                name: 'Add',
                                checked: false,
                            },
                            {
                                id: 'general_service_contact_update',
                                name: 'Update',
                                checked: false,
                            },
                            {
                                id: 'general_service_contact_delete',
                                name: 'Delete',
                                checked: false
                            }
                        ],
                    }
                ]
            },
           
           
        ];
    }
}