import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Config Module
import { environment } from 'src/environments/environment';
import { HttpUtilities } from 'src/app/common/helpers/http-utils.helper';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    headers: HttpHeaders;
    API_URI_PROVINCE = environment.API_URI + '/v1/provinces';
    API_URI_DISTRICT = environment.API_URI + '/v1/districts';
    API_URI_WARD = environment.API_URI + '/v1/wards';

    constructor(
        private http: HttpClient,
        private httpService: HttpUtilities
    ) {
        this.headers = this.httpService.getHttpClientHeaders();
    }

    /**
     * List Province
     * @param {String} params
     */
    listProvince(params: any = {}) {
        return this.http.get(
            this.API_URI_PROVINCE,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * List District
     * @param {String} params
     */
    listDistrict(params: any = {}) {
        return this.http.get(
            this.API_URI_DISTRICT,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * List Ward
     * @param {String} params
     */
    listWard(params: any = {}) {
        return this.http.get(
            this.API_URI_WARD,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }
}
