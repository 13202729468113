export class Brand {
    'id': string;
    'slug': string;
    'icon': string;
    'name': string;
    'value': string;
    'content': string;
    'position': number;
    'attribute_code': string;
    'attribute_group': string;

    // social seo
    'meta_url': string;
    'meta_title': string;
    'meta_image': string;
    'meta_keyword': string;
    'meta_description': string;

    // manager
    'is_active': boolean;
    'created_by': object;
    'created_at': Date;
    'updated_by': object;
    'updated_at': Date;

    /**
     * Set data
     * @param {*} state
     */
    setData(state) {
        this.slug = state.slug;
        this.icon = state.icon;
        this.name = state.name;
        this.value = state.name;
        this.content = state.content;
        this.position = state.position;
        this.attribute_code = state.attribute_code;
        this.attribute_group = state.attribute_group;

        this.meta_image = this.icon;
        this.meta_url = state.meta_url;
        this.meta_title = state.meta_title;
        this.meta_keyword = state.meta_keyword;
        this.meta_description = state.meta_description;
    }
}