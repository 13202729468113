// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Authorize
import { AuthGuard } from './common/guards/auth.guard';

// Components
import { LayoutComponent } from './view/themes/layout/layout.component';

// Modules
import { AppPreloadingStrategy } from './app-lazy-load-routing.module';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./view/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./view/pages/home/home.module').then(m => m.HomeModule),
            },

            {
                path: 'config',
                loadChildren: () => import('./view/pages/config/config.module').then(m => m.ConfigModule)
            },

            {
                path: 'setting',
                loadChildren: () => import('./view/pages/setting-group/setting-group.module').then(m => m.SettingModule),
            },
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
        ],
    },
    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AppPreloadingStrategy
    ]
})

export class AppRoutingModule { }
