import { Component, OnInit } from '@angular/core';

// Modules
import { ErrorModel } from 'src/app/common/models';
import { Staff } from 'src/app/view/pages/config/_models';
import { StaffService } from 'src/app/view/pages/config/_services';
import { ModalService, LocationService, ToastService } from 'src/app/common/services';

@Component({
    selector: 'app-modal-profile-info',
    templateUrl: './modal-profile-info.component.html',
    providers: [
        StaffService,
        LocationService
    ]
})
export class ModalProfileInfoComponent implements OnInit {
    // Declaration State
    staffId: string;
    formState: any = {};

    // Declaration Config
    configurableWards: any = [];
    configurableProvinces: any = [];
    configurableDistricts: any = [];

    constructor(
        private locationService: LocationService,
        private toastService: ToastService,
        private modalService: ModalService,
        private staffService: StaffService
    ) {
        // Load Configuration
        this.configurableProvinces = this.locationService.listProvince();
    }

    /**
     * Load detail profile
     */
    async ngOnInit() {
        try {
            await this.staffService.detail(
                this.staffId
            ).then(res => {
                if (res.code) {
                    const error = new ErrorModel(res);
                    this.toastService.show(
                        error.getMessage,
                        { classname: 'bg-danger text-light' }
                    );
                    return;
                }

                // Handle Success
                this.formState = res.data;
            });

            // Load provinces
            await this.locationService.listProvince()
                .then(res => this.configurableProvinces = res.code ? [] : res.data);

            // Load sub locations
            if (this.formState.province && this.formState.district) {
                await this.locationService.listDistrict({
                    province_code: this.formState.province?.id
                }).then(res => this.configurableDistricts = res.code ? [] : res.data);
                await this.locationService.listWard({
                    district_code: this.formState.province?.id
                }).then(res => this.configurableWards = res.code ? [] : res.data);
            }
        } catch (ex) {
            throw (ex);
        }
    }

    /**
     * Load Sub Location
     * @param {*} parentId
     * @param {*} groupName
     */
    async onLoadSubLocation(parentId: string, groupName: string): Promise<void> {
        try {
            if (groupName === 'district') {
                this.configurableDistricts = [];
                this.formState.district = null;
                this.formState.ward = null;
                await this.locationService.listDistrict({
                    province_code: parentId
                }).then(res => this.configurableDistricts = res.code ? [] : res.data);
            } else if (groupName === 'ward') {
                this.configurableWards = [];
                this.formState.ward = null;
                await this.locationService.listWard({
                    district_code: parentId
                }).then(res => this.configurableWards = res.code ? [] : res.data);
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Update
     * @param {*} formState
     */
    onUpdate() {
        const { password, re_password } = this.formState;
        if (password && password !== re_password) {
            this.toastService.show(
                'Mật khẩu nhập lại không đúng!',
                { classname: 'bg-danger text-light' }
            );
            return;
        }

        this.staffService.update(
            this.staffId,
            new Staff(this.formState)
        ).then(res => {
            if (res.code) {
                const error = new ErrorModel(res);
                this.toastService.show(
                    error.getMessage,
                    { classname: 'bg-danger text-light' }
                );
                return false;
            }

            // TODO:: Success
            this.toastService.show(
                'Cập nhật thành công!',
                { classname: 'bg-successtext-light' }
            );
            return true;
        }).catch(ex => {
            throw (ex);
        });
    }

    /**
     * Close modal
     * @private
     */
    closeModal() {
        this.modalService.close(ModalProfileInfoComponent);
    }
}
