<ng-container>
    <ngb-datepicker
        #datepicker
        [displayMonths]="2"
        [dayTemplate]="time"
        (dateSelect)="onSelect($event)"
        outsideDays="hidden"
    >
    </ngb-datepicker>
    <ng-template
        #time
        let-date
        let-focused="focused"
    >
        <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRangeDate(date)"
            [class.faded]="isHoveredDate(date) || isInsideDate(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
        >
            {{ date.day }}
        </span>
    </ng-template>
</ng-container>