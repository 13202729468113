import axios from 'axios';
import { environment } from 'src/environments/environment';
export default class UploadAdapter {
    // Variable
    loader;
    API_URI = environment.API_URI + '/v1/images/upload-single?group=products';

    constructor(loader) {
        // CKEditor 5's fileLoader instance.
        this.loader = loader;
    }

    /**
     * Upload image adapter
     * @param loader
     */
    upload() {
        if (this.loader.file) {
            return this.loader.file.then(
                file => new Promise((resolve, reject) => {
                    if (file) {
                        // Prepare file
                        const formData = new FormData();
                        formData.append('file', file);

                        // Submit request
                        axios.post(
                            this.API_URI,
                            formData,
                            {
                                headers: {
                                    'X-CSRF-TOKEN': 'CSFR-Token',
                                    Authorization: 'Bearer <JSON Web Token>'
                                },
                                responseType: 'json'
                            }
                        ).then(res => {
                            if (res.status !== 200) {
                                reject(res.statusText);
                            }

                            // Handle success
                            resolve({
                                default: res.data?.url
                            });
                        }).catch(ex => {
                            reject(ex);
                        });
                    }
                }));
        }
    }
};