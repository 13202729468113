export const environment = {
    // Mode Config
    production: false,

    // App Config
    PORT: 4200,
    VERSION: '1.0.2.6',
    // API_URI: 'http://localhost:3002',
    API_URI: 'https://api.juliesandlauinternational.com',
    CDN_URI: 'https://cdn.juliesandlauinternational.com',
    WEB_URI: 'https://juliesandlauinternational.com',

    // Layout Config
    LAYOUT_CONFIG: {
        THEME_LAYOUT: 'julie',
        LOGO_ICON: './assets/media/icons/admin/logo.png',
        LOGO_LIGHT_ICON: './assets/media/icons/admin/logo-light.svg',
        LOGIN_BACKGROUND: '../../../../../assets/media/icons/admin/login_background.webp'
    }
};
