import { DBConfig } from 'ngx-indexed-db';

const IndexedDbSchema = {
    CART: 'tbl_carts',
    INVOICE: 'tbl_orders',
    PRODUCT: 'tbl_products',
    CUSTOMER: 'tbl_customers',
    RETURN: 'tbl_order_returns',
    ORDER: 'tbl_order_requests',
};

const IndexedDbConfig: DBConfig = {
    name: 'service_cms',
    version: 1,
    objectStoresMeta: [
        {
            storeConfig: {
                keyPath: 'uid',
                autoIncrement: true
            },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                { name: 'name', keypath: 'name', options: { unique: false } },
                { name: 'phone', keypath: 'phone', options: { unique: false } }
            ],
            store: IndexedDbSchema.CUSTOMER,
        },
        {
            storeConfig: {
                keyPath: 'uid',
                autoIncrement: true
            },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                { name: 'sku', keypath: 'sku', options: { unique: false } },
                { name: 'name', keypath: 'name', options: { unique: false } },
                { name: 'unit', keypath: 'unit', options: { unique: false } },
                { name: 'brand', keypath: 'brand', options: { unique: false } },
                { name: 'stocks', keypath: 'stocks', options: { unique: false } },
                { name: 'price', keypath: 'price', options: { unique: false } },
                { name: 'normal_price', keypath: 'normal_price', options: { unique: false } },
                { name: 'original_price', keypath: 'original_price', options: { unique: false } },
                { name: 'thumbnail_url', keypath: 'thumbnail_url', options: { unique: false } },
            ],
            store: IndexedDbSchema.PRODUCT,
        },
        {
            storeConfig: {
                keyPath: 'uid',
                autoIncrement: true
            },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                { name: 'type', keypath: 'type', options: { unique: false } },
                { name: 'group', keypath: 'group', options: { unique: false } },
                { name: 'name', keypath: 'name', options: { unique: false } },
                { name: 'index', keypath: 'index', options: { unique: false } },
                { name: 'store', keypath: 'store', options: { unique: false } },
                { name: 'order', keypath: 'order', options: { unique: false } },
                { name: 'return', keypath: 'return', options: { unique: false } },
                { name: 'invoice', keypath: 'invoice', options: { unique: false } },
                { name: 'status', keypath: 'status', options: { unique: false } },
                { name: 'customer', keypath: 'customer', options: { unique: false } },
                { name: 'channel', keypath: 'channel', options: { unique: false } },
                { name: 'products', keypath: 'products', options: { unique: false } },
                { name: 'payments', keypath: 'payments', options: { unique: false } },
                { name: 'deliveries', keypath: 'deliveries', options: { unique: false } },
                { name: 'total_coin', keypath: 'total_coin', options: { unique: false } },
                { name: 'total_point', keypath: 'total_point', options: { unique: false } },
                { name: 'total_quantity', keypath: 'total_quantity', options: { unique: false } },
                { name: 'total_price', keypath: 'total_price', options: { unique: false } },
                { name: 'total_debt', keypath: 'total_debt', options: { unique: false } },
                { name: 'total_paid', keypath: 'total_paid', options: { unique: false } },
                { name: 'total_unpaid', keypath: 'total_unpaid', options: { unique: false } },
                { name: 'total_shipping_fee', keypath: 'total_shipping_fee', options: { unique: false } },
                { name: 'total_discount_value', keypath: 'total_discount_value', options: { unique: false } },
                { name: 'total_original_price', keypath: 'total_original_price', options: { unique: false } },
                { name: 'total_require_payment', keypath: 'total_require_payment', options: { unique: false } },
                { name: 'total_price_before_discount', keypath: 'total_price_before_discount', options: { unique: false } },
                { name: 'created_by', keypath: 'created_by', options: { unique: false } },
                { name: 'is_delivery', keypath: 'is_delivery', options: { unique: false } },
                { name: 'is_payment', keypath: 'is_payment', options: { unique: false } },
                { name: 'is_active', keypath: 'is_active', options: { unique: false } },
            ],
            store: IndexedDbSchema.CART,
        }
    ]
}

export {
    IndexedDbSchema,
    IndexedDbConfig
};