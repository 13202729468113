import { Component, EventEmitter, Input, Output } from '@angular/core';

// Modules
import { ErrorModel } from 'src/app/common/models';
import { ToastService, UploadService } from 'src/app/common/services';

@Component({
    selector: 'button-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})

export class UploadImageComponent {
    // Declaration Input
    @Input() image: string;
    @Input() button = true;
    @Input() groupName: string;

    // Declaration Output
    @Output() callback = new EventEmitter<string>();

    constructor(
        private toast: ToastService,
        private service: UploadService
    ) { }

    /**
     * Upload
     * @param {*} file
     */
    async onUploadImage(file: any) {
        if (file) {
            const input = file.target;
            const formData = new FormData();
            formData.append('file', input.files[0]);
            await this.service.single(
                formData,
                this.groupName
            ).then(res => {
                if (res.code) {
                    const error = new ErrorModel(res);
                    this.toast.show(error.getMessage, { classname: 'bg-danger text-light' });
                    return null;
                }

                // callback to component
                return this.callback.emit(res.url);
            }).catch(ex => {
                throw new Error(ex);
            });
        }
    }
}
