<div
    class="form-upload-file"
    [class.to-top]="errors.length"
>
    <div class="form-container">
        <div class="upload-box">
            <label class="col-form-label-md">Thêm sản phẩm từ file excel</label>
            <small>(Tải về file mẫu: <a [href]="urlDownload">Excel file</a>)</small>
            <label class="btn btn-sm btn-success">
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    (change)="onUploadExcel($event)"
                />
                <i
                    class="fa fa-file-excel mr-1"
                    aria-hidden="true"
                ></i>
                <span>Chọn file dữ liệu</span>
            </label>
        </div>
        <div
            class="upload-error"
            *ngIf="errors"
        >
            <span *ngFor="let error of errors; let index = index">
                <span *ngIf="error">
                    Dòng {{index + 2}}: Mã hàng {{error}} không có trên hệ thống hoặc ngừng kinh doanh
                </span>
                <span *ngIf="!error">
                    Dòng {{index + 2}}: {{error || 'Dữ liệu không hợp lệ, bạn cần format dữ liệu như file mẫu'}}
                </span>
            </span>
        </div>
    </div>
</div>