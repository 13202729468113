export const DefaultPageConfig = {
    '/transaction/invoice': {
        LIST_STATUS: [
            {
                id: 'darft',
                name: 'Chờ duyệt'
            },
            {
                id: 'processing',
                name: 'Đang xử lý'
            },
            {
                id: 'cancelled',
                name: 'Đã hủy đơn'
            },
            {
                id: 'completed',
                name: 'Đã xử lý'
            }
        ],
        LIST_PAYMENT_METHOD: [
            {
                id: 1,
                name: 'Tiền mặt'
            },
            {
                id: 2,
                name: 'Chuyển khoản'
            },
            {
                id: 3,
                name: 'Quẹt thẻ'
            }
        ]
    },
    '/transaction/import': {
        LIST_STATUS: [
            {
                id: 'darft',
                name: 'Phiếu tạm'
            },
            {
                id: 'cancelled',
                name: 'Đã hủy đơn'
            },
            {
                id: 'completed',
                name: 'Đã nhập hàng'
            }
        ]
    },
    '/transaction/transfer': {
        LIST_STATUS: [
            {
                id: 'darft',
                name: 'Phiếu tạm'
            },
            {
                id: 'pending',
                name: 'Đang chờ'
            },
            {
                id: 'processing',
                name: 'Đang chuyển hàng'
            },
            {
                id: 'completed',
                name: 'Đã nhận hàng'
            },
            {
                id: 'cancelled',
                name: 'Đã hủy đơn'
            }
        ]
    },
    '/transaction/delivery': {
        LIST_STATUS: [
            {
                id: 'pending',
                name: 'Chờ xử lý'
            },
            {
                id: 'picking',
                name: 'Đang lấy hàng'
            },
            {
                id: 're_pick',
                name: 'Chờ lấy lại'
            },
            {
                id: 'picked',
                name: 'Đã lấy hàng'
            },
            {
                id: 'delivering',
                name: 'Đang giao hàng'
            },
            {
                id: 're_delivery',
                name: 'Chờ giao lại'
            },
            {
                id: 'delivered',
                name: 'Giao thành công'
            },
            {
                id: 'pending_return',
                name: 'Chờ chuyển hoàn'
            },
            {
                id: 'returning',
                name: 'Đang chuyển hoàn'
            },
            {
                id: 're_return',
                name: 'Chờ chuyển hoàn lại'
            },
            {
                id: 'confirm_pending_return',
                name: 'Chờ xác nhận hoàn'
            },
            {
                id: 'returned',
                name: 'Đã chuyển hoàn'
            },
            {
                id: 'cancelled',
                name: 'Đã hủy'
            }
        ],
        LIST_PROVIDER: [
            {
                id: 'ghn',
                name: 'Giao hàng nhanh'
            },
            {
                id: 'ghtk',
                name: 'Giao hàng tiết kiệm'
            },
            {
                id: 'ahamove',
                name: 'Ahamove'
            }
        ]
    },
    '/transaction/stock-take': {
        LIST_STATUS: [
            {
                id: 'darft',
                name: 'Phiếu tạm'
            },
            {
                id: 'completed',
                name: 'Đã cân bằng'
            },
            {
                id: 'cancelled',
                name: 'Đã hủy đơn'
            }
        ]
    }
}