import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { NgxPrintModule } from 'ngx-print';
import { TreeviewModule } from 'ngx-treeview';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { ToastComponent } from './toast/toast.component';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { UploadImageComponent } from './upload/upload-image/upload-imave.component';
// import { ProductSearchComponent } from './search/product-search/product-search.component';
// import { AttributeSearchComponent } from './search/attribute-search/attribute-search.component';

import { CategorySearchComponent } from './search/category-search/category-search.component';
import { DateRangeComponent } from './date-picker/date-range/date-range.component';
import { TimePickerComponent } from './date-picker/time-picker/time-picker.component';

// Upload File
import { UploadFileExcelComponent } from './upload/upload-excel/upload-excel.component';

// Download file
// import { ModalExtraSearchComponent } from './search/modal-extra-search/modal-extra-search.component';
import { PopupDownloadComponent } from './file-group/popup-download/popup-download.component';


// Local Config
const CURRENCY_CONFIG = {
    align: 'right',
    allowNegative: true,
    allowZero: true,
    decimal: ',',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: ',',
    nullable: true
};


@NgModule({
    declarations: [
        ToastComponent,
        // AttributeSearchComponent,
        CategorySearchComponent,
        // ProductSearchComponent,
        UploadImageComponent,
        TimePickerComponent,
        DateRangeComponent,
        CkeditorComponent,
        PopupDownloadComponent,

        // Upload File
        UploadFileExcelComponent,

        // Download file
        // ModalExtraSearchComponent,
    ],
    exports: [
        ToastComponent,
        CategorySearchComponent,
        // AttributeSearchComponent,
        // ProductSearchComponent,
        UploadImageComponent,
        TimePickerComponent,
        DateRangeComponent,
        CkeditorComponent,
        PopupDownloadComponent,
        // Upload File
        UploadFileExcelComponent
    ],
    imports: [
        CommonModule,
        FormsModule,

        // ng module
        NgbModule,
        NgbNavModule,
        NgSelectModule,
        NgbToastModule,
        CKEditorModule,
        NgxPrintModule,
        NgxDatatableModule,
        TreeviewModule.forRoot(),
        NgxCurrencyModule.forRoot(CURRENCY_CONFIG),
    ]
})

export class PartialsModule { }
