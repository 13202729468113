<ng-container *ngIf="button">
    <div class="image-box" [class.shadow]="image" [class.contain]="!image">
        <img [src]="image || './assets/media/images/default.png'" alt="b2c" />
        <div class="box-action">
            <label class="btn btn-sm mb-0" title="Upload ảnh">
                <i class="fas fa-cloud-upload-alt"></i>
                <input type="file" name="profile_avatar" class="i-upload" accept=".png, .jpg, .jpeg , .webp"
                    (change)="onUploadImage($event)" />
            </label>
            <label class="btn btn-sm mb-0" title="Xóa ảnh" *ngIf="image" (click)="callback.emit(null)">
                <i class="fas fa-trash-alt"></i>
            </label>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!button">
    <input type="file" name="profile_avatar" class="i-upload" accept=".png, .jpg, .jpeg, .webp"
        (change)="onUploadImage($event)" />
</ng-container>