import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Config Module
import { environment } from 'src/environments/environment';
import { HttpUtilities } from 'src/app/common/helpers/http-utils.helper';

// Modules

@Injectable()
export class AttributeService {
    headers: HttpHeaders;
    API_URI = environment.API_URI + '/v1/attributes';

    constructor(
        private http: HttpClient,
        private httpService: HttpUtilities
    ) {
        this.headers = this.httpService.getHttpClientHeaders();
    }

    /**
     * List
     *
     * @param {String} queryParams
     */
    list(params: any = {}) {
        delete params.page;
        return this.http.get(
            this.API_URI,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Create
     * @param {*} requestBody
     */
    create(requestBody: any = {}) {
        return this.http.post(
            this.API_URI,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Detail
     * @param {String} attributeId
     */
    detail(attributeId: any) {
        return this.http.get(
            this.API_URI + `/${attributeId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Update
     * @param {String} attributeId
     * @param {Object} requestBody
     */
    update(attributeId: string, requestBody: any = {}) {
        return this.http.put(
            this.API_URI + `/${attributeId}`,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Delete
     * @param {String} attributeId
     */
    delete(attributeId: string) {
        return this.http.delete(
            this.API_URI + `/${attributeId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }
}
