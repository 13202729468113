import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Modules
import { ErrorModel } from 'src/app/common/models';
import { ToastService, ExcelService, LayoutService } from 'src/app/common/services';

@Component({
    selector: 'button-upload-excel',
    templateUrl: './upload-excel.component.html',
    styleUrls: ['./upload-excel.component.scss'],
    providers: [
        ExcelService,
        LayoutService
    ]
})
export class UploadFileExcelComponent implements OnInit {
    // Delcaration IN | OUT
    @Input() groupName = 'products';
    @Input() fileExample?: string;
    @Output() callback = new EventEmitter<object>();

    // Variables
    errors: any = [];
    urlDownload: string;
    currentStore: any = {};

    constructor(
        private excelService: ExcelService,
        private toastService: ToastService,
        private layoutService: LayoutService,
    ) {
        this.currentStore = this.layoutService.getCurrentStore();
    }

    ngOnInit() {
        // Config url download file
        this.urlDownload = '../../../../../../assets/media/files/' + this.fileExample;
    }

    /**
     * Load Item
     * @param item
     */
    onLoadItem(item: any = {}) {
        try {
            item.option_id = item.id;
            item.id = item.parent_id;
            item.total_quantity = 0;
            item.total_price = 0;
            item.total_final_quantity = 0;

            // load stock
            if (item.stocks && item.stocks.length) {
                item.stocks.map(stock => {
                    if (stock.id === this.currentStore.id) {
                        item.total_quantity += stock.total_quantity;
                        item.total_final_quantity += stock.total_quantity;
                    }
                });
            }
            return item;
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Handle upload file excel
     * @param file
     */
    async onUploadExcel(file: any) {
        if (file) {
            const input = file.target;
            const formData = new FormData();
            formData.append('file', input.files[0]);
            await this.excelService.readFile(
                formData,
                this.groupName
            ).then(response => {
                if (response.code) {
                    const error = new ErrorModel(response);
                    this.toastService.show(
                        error.getMessage,
                        { classname: 'bg-danger text-light' }
                    );
                    return null;
                }

                // callback to component
                const { items, errors } = response.data;
                this.errors = errors || [];
                if (items.length && !errors.length) {
                    items.map(i => {
                        this.onLoadItem(i);
                        return i;
                    });
                    this.callback.emit(items);
                }
            }).catch(ex => {
                throw new Error(ex);
            });
        }
    }
}
