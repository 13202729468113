import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable()
export class IndexedDBService {
    constructor(
        private service: NgxIndexedDBService
    ) { }

    /**
     * Save
     * @param {*} schema
     * @param {*} object
     */
    save(schema: string, object: any = {}) {
        return this.service.add(
            schema,
            object
        ).toPromise().then(key => {
            return this.service.getByKey(schema, key)
                .toPromise()
                .then(data => data);
        });
    }

    /**
     * Bulk Create
     * @param {*} schema
     * @param {Array} data
     */
    bulkCreate(schema: string, data: any[]) {
        const promises = data.map((i) => this.save(schema, i));
        return Promise.all(promises);
    }

    /**
     * Update
     * @param {*} schema
     * @param {*} object
     * @param {*} uid
     */
    update(schema: string, object: any = {}) {
        return this.service.update(schema, object)
            .toPromise().then(data => data);
    }

    /**
     * Bulk Update
     * @param {*} schema
     * @param {Array} data
     */
    bulkUpdate(schema: string, data: any[]) {
        const promises = data.map((i) => this.update(schema, i));
        return Promise.all(promises);
    }

    /**
     * Find By Id
     * @param schema
     * @param uid
     */
    findById(schema: string, uid: number) {
        return this.service.getByKey(schema, uid,).
            toPromise().then(data => data);
    }

    /**
     * Find By Index
     * @param schema
     */
    findAll(schema: string) {
        return this.service.getAll(schema)
            .toPromise().then(data => data);
    }

    /**
     * Find By Index
     * @param schema
     */
    findAllByIndex(schema: string, field: string, value: string) {
        return this.service.getAllByIndex(
            schema,
            field,
            IDBKeyRange.only(value)
        ).toPromise().then(data => data);
    }

    /**
     * Delete
     * @param schema
     * @param key
     */
    delete(schema: string, key: number) {
        return this.service.delete(schema, key)
            .toPromise().then(data => data);
    }

    /**
     * Clear
     * @param schema
     */
    clear(schema: string) {
        this.service.clear(schema)
            .toPromise()
            .then(data => data);
    }
}
