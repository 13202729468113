import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultPageConfig } from 'src/app/config/dynamic-page.config';

@Injectable()
export class DynamicPageService {
    public pageConfig: Observable<any>;
    private pageConfigSubject = new BehaviorSubject<any>({});

    constructor() {
        this.pageConfig = this.pageConfigSubject.asObservable();
        this.loadConfig();
    }

    /**
     * Init config
     */
    private loadConfig() {
        this.setConfig(DefaultPageConfig);
    }

    /**
     * Set Config
     * @param pageConfig
     */
    private setConfig(pageConfig) {
        this.pageConfigSubject.next(pageConfig);
    }
}
