import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Config Module
import { environment } from 'src/environments/environment';
import { HttpUtilities } from 'src/app/common/helpers/http-utils.helper';

// Modules
import { Role } from 'src/app/view/pages/config/_models';

@Injectable()
export class RoleService {
    headers: HttpHeaders;
    API_URI = environment.API_URI + '/v1/roles';

    constructor(
        private http: HttpClient,
        private httpService: HttpUtilities
    ) {
        this.headers = this.httpService.getHttpClientHeaders();
    }

    /**
     * List
     *
     * @param {String} queryParams
     */
    list(params: any = {}) {
        delete params.page;
        return this.http.get(
            this.API_URI,
            {
                params: this.httpService.getFindHTTPParams(params),
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Create
     * @param {Role} requestBody
     */
    create(requestBody: Role) {
        return this.http.post(
            this.API_URI,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Detail
     * @param {String} roleId
     */
    detail(roleId: any) {
        return this.http.get(
            this.API_URI + `/${roleId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Update
     * @param {String} roleId
     * @param {Role} requestBody
     */
    update(roleId: string, requestBody: Role) {
        return this.http.put(
            this.API_URI + `/${roleId}`,
            requestBody,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Delete
     * @param {String} roleId
     */
    delete(roleId: string) {
        return this.http.delete(
            this.API_URI + `/${roleId}`,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Block
     * @param {String} roleId
     */
    block(roleId: string) {
        return this.http.post(
            this.API_URI + `/${roleId}/block`,
            null,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Active
     * @param {String} roleId
     */
    active(roleId: string) {
        return this.http.post(
            this.API_URI + `/${roleId}/active`,
            null,
            {
                headers: this.headers
            }
        ).toPromise().then(
            result => JSON.parse(
                JSON.stringify(result)
            )
        );
    }

    /**
     * Get role status
     */
    listStatuses() {
        return [
            { id: '', name: 'All', selected: true },
            { id: 'active', name: 'Active', selected: false },
            { id: 'inactive', name: 'Inactive', selected: false }
        ]
    }
}
