import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

// Modules
import { LayoutService, ToastService } from '../services';
import { DynamicMenuConfig } from 'src/app/config/dynamic-menu.config';
import { includes, isNil } from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
    // Declaration Config
    configurablePermissions: any = [];

    constructor(
        private router: Router,
        private layoutService: LayoutService,
        private toastService: ToastService
    ) {
        this.configurablePermissions = this.layoutService.listPermission();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        try {
            const token = this.layoutService.getToken();
            const expiredAt = this.layoutService.getTokenExpire();
							console.log( moment(Date.now()).unix(),
							parseInt(expiredAt, 10))
            if (!token) {
                this.router.navigate(['auth/login']);
                return false;
            }
            if (
                moment(Date.now()).unix() >=
                parseInt(expiredAt, 10)
            ) {
                // TODO:: Refresh token
                this.layoutService.resetConfig();
                alert('phiên đăng nhập đã hết hạn');
                this.router.navigate(['auth/login']);
                return false;
            }

            return true;
        } catch (ex) {
            this.router.navigate(['auth/login']);
            return false;
        }
    }

    /**
     * Authorize
     * @returns {boolean}
     */
    authorize(url) {
        try {
            const page = url.split('?')[0];
            const currentMenu = this.onLoadMenu(page);
            if (isNil(currentMenu)) {
                return true
            };
            if (
                currentMenu.permissions && !currentMenu.permissions?.length &&
                currentMenu.submenu && !currentMenu.submenu?.length
            ) {
                return true;
            }
            if (currentMenu.permisisons && currentMenu.permisisons.length) {
                const permissions = currentMenu.permisisons(
                    permission => includes(this.configurablePermissions, permission)
                );
                const redirect = permissions.find(
                    p => p === true
                );
                return redirect ? true : false;
            }
            return includes(this.configurablePermissions, currentMenu.permission);
        } catch (error) {
            throw Error(error);
        }
    }

    /**
     * Get menu
     * @param {*} page
     */
    onLoadMenu(page: string) {
        let currentMenu = null;
        const menus = DynamicMenuConfig.items;
        menus.forEach(menu => {
            if (menu.page === page) {
                currentMenu = menu;
            }
            if (menu.submenu && menu.submenu.length) {
                menu.submenu.forEach(sub => {
                    if (sub.page === page) {
                        currentMenu = sub;
                    }
                })
            }
        });
        return currentMenu;
    }
}
