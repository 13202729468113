<ng-container>
    <div class="modal-header border-0 solid">
        <h6 class="modal-title">
            <span> Xử lý thiết lập xuất file</span>
        </h6>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="onClose()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div style="width: 500px; padding: 5px" class="modal-content border-0">
        <div class="file-data row">
            <div class="col-md-12">
                <div class="d-flex align-items-center text-dark p-2">
                    <i class="fa fa-cloud-download-alt mr-2 text-secondary">
                        <!-- ICON -->
                    </i>
                    <span
                        *ngIf="fileName"
                        class="text"
                        [style.fontSize]="'14px'"
                        style="
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        "
                    >
                        {{ fileName }}
                    </span>
                    <span
                        *ngIf="!fileName"
                        class="text"
                        [style.fontSize]="'14px'"
                        [class.text-danger]="fileError"
                    >
                        {{ fileError ? fileError : "Đang chuẩn bị file..." }}
                    </span>
                    <i
                        *ngIf="!loading && !fileError"
                        class="fa fa-check-circle text-success ml-2"
                    >
                        <!-- ICON -->
                    </i>
                </div>
                <div class="d-flex align-items-center text-primary">
                    <ng-container *ngIf="loading">
                        <div class="d-flex">
                            <div
                                class="spinner-border text-secondary spinner-border-sm small ml-2 mr-3"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="text-secondary small">
                                Đang xử lý dữ liệu....
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loading && !fileError">
                        <a
                            class="small"
                            [href]="fileUrl"
                            (click)="onClose()"
                            style="margin-left: 2.5rem"
                        >
                            Nhấn vào đây để tải xuống
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
